.contact_profile_headshot {
    border-radius: 50rem;
    overflow: hidden;
    width: 15rem;
    height: 15rem;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0 auto 2rem;
}

#contact h2 {
    margin-bottom: 2rem;
}

.container.contact__container {
    width: 80%;
    display: grid;
    /* grid-template-columns: 30% 58%; */
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
    justify-content: center;
    flex-wrap: wrap;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    width: 18rem;
    /* transition: var(--transition); */
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/* ===================== FORM ========================= */
form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}

/* ================= MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1024px){
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* ================= MEDIA QUERIES (SMALL DEVICES) ================ */
@media screen and (max-width: 600px){
   .container.contact__container {
       width: var(--container-width-sm);
   }
}